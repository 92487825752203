window.projectsSliderFeatureHandler = () => {
  document.addEventListener('DOMContentLoaded', () => {
    sliderEvents();
    showSlider();
  });

  const sliderEvents = () => {
    const sliders = document.querySelectorAll('.jsSlickProjectsSliderFeature');

    sliders?.forEach(slider => {
      $(slider).slick({
        arrows: true,
        dots: false,
        gap: 50,
        infinite: true,
        lazyLoad: 'ondemand',
        slidesToScroll: 1,
        slidesToShow: 5,
        speed: 500,
        responsive: [
          {
            breakpoint: 2560,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: gridBreakpoints.xl,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: gridBreakpoints.md,
            settings: {
              draggable: true,
              slidesToScroll: 1,
              slidesToShow: 1,
            },
          },
        ],
      });

      // Automatic scrolling logic
      let autoScrollInterval;

      const startAutoScroll = () => {
        autoScrollInterval = setInterval(() => {
          $(slider).slick('slickNext');
        }, 3000);
      };

      const stopAutoScroll = () => {
        clearInterval(autoScrollInterval);
      };

      // Start auto-scrolling on page load
      startAutoScroll();

      // Pause auto-scrolling when hovering over the slider
      slider.addEventListener('mouseenter', stopAutoScroll);
      slider.addEventListener('mouseleave', startAutoScroll);
    });
  };

  const showSlider = () => {
    const modules = document.querySelectorAll('.jsProjectsSliderFeature');

    modules?.forEach(module => {
      module.classList.add('visible');
    });
  };
};
