window.modalImageFullScreenHandler = function () {
  $(document).ready(() => {
    modalImageFullScreenEvents();
  });

  const modalImageFullScreenEvents = function () {
    const imageWrapper = document.querySelector(".jsModalSliceImageWrapper");
    const imageModal = document.querySelector('.jsModalSlideFullScreenImg');

    imageModal.addEventListener('click', e => {
      e.stopImmediatePropagation();
      if (e.target.contains(imageWrapper) && !e.target.classList.contains('jsModalSliceLargeDownloadLink')) {
        imageModal.style.visibility = 'hidden';
        imageModal.style.opacity = '0';

        const largeImg = document.querySelector('.jsModalSlideLargeImg');
        if (largeImg) {
          largeImg.src = '';
        }

        document.body.style.overflow = 'auto';
      }
    })

    // show full screen image in gallery
    $(document).on('click', '.jsGalleryModalFullImage', function () {
      $('.jsModalSlideFullScreenImg').css({'visibility': 'visible', 'opacity': '1'});
      $('.jsModalSlideLargeImg').attr('src', $(this).data('image-url'));
      if ($('.jsModalSliceLargeDownloadLink').length > 0) {
        $('.jsModalSliceLargeDownloadLink').attr('href', $(this).data('image-url'))
      }
      $('body').css({'overflow': 'hidden'});
    });

    // hide full screen image
    $('.jsModalSlideLargeImg').on('click', function () {
      $(this).parents('.jsModalSlideFullScreenImg').css({'visibility': 'hidden', 'opacity': '0'});
      $(this).attr('src', '');
      $('body').css({'overflow': 'auto'});
    });

    // close full screen image
    $('.jsModalSlideFullScreenImgClose').on('click', function () {
      $(this).parents('.jsModalSlideFullScreenImg').css({'visibility': 'hidden', 'opacity': '0'});
      $(this).siblings('.jsModalSlideLargeImg').attr('src', '');
      $('body').css({'overflow': 'auto'});
    });
  };
};
